module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-ZWCL3N5RE5"],"pluginConfig":{"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rewind","short_name":"Rewind","start_url":"/","background_color":"#003243","theme_color":"#003243","display":"minimal-ui","icon":"src/images/rewind-icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7266419eccb331dda1a4001e1a5d6de3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","locale":"en_US","url":"https://rewind.netlify.app","site_name":"Rewind","images":[{"url":"https://rewind.netlify.app/static/dbee8ce77ad2295ef15480f9a7db2c33/936bb/bookmarks-by-year%402x.webp","width":697,"height":461,"alt":"Rewind"}]},"twitter":{"handle":"@j_____________n","cardType":"app"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
